<template>
  <v-select
    v-bind="propsFormFields"
    :disabled="disabled"
    :loading="loading"
    v-model="editPickerValue"
    :items="languages"
    item-text="name"
    item-value="value"
    :label="$t('common.languageIso639_1')"
    :no-data-text="$t('common.selectNoDataText')"
    :error-messages="errorMessages"
    @change="pickerChanged"
    @blur="$emit('blur')"
    :clearable="clearable"
    data-cy="select-language"
  ></v-select>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";

export default {
  name: "LanguagePickerSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    pickerValue: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    changed: false,
    editPickerValue: null,
  }),
  computed: {
    languages() {
      let languages = APP_CONSTANTS.LANGUAGES.map((item) => {
        return {
          name: this.$t(`languages.${item.name}`),
          value: item.value,
        };
      });
      return languages;
    },
  },
  watch: {
    pickerValue: {
      immediate: true,
      handler: function (value) {
        // if (!this.changed) {
        this.editPickerValue = value;
        // }
      },
    },
  },
  created() {},
  methods: {
    pickerChanged(value) {
      this.changed = true;
      this.$emit("pickerChanged", value);
    },
  },
};
</script>
